import React from 'react'

export default function RightSvg() {
  return (
    <svg viewBox="0 0 1024 1024" version="1.1" width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" p-id="1739">
      <path
        d="M512 64C264.96 64 64 264.96 64 512s200.96 448 448 448 448-200.96 448-448S759.04 64 512 64zM661.76 535.968l-160.256 158.496c-6.24 6.144-14.368 9.248-22.496 9.248-8.256 0-16.512-3.168-22.752-9.504-12.416-12.576-12.32-32.8 0.256-45.248l137.408-135.904-136.288-136.672c-12.48-12.512-12.448-32.768 0.064-45.248 12.512-12.512 32.768-12.448 45.248 0.064l158.912 159.36c0.032 0.032 0.032 0.064 0.064 0.096s0.064 0.032 0.096 0.064c2.944 2.976 5.056 6.432 6.592 10.048 0.064 0.128 0.224 0.256 0.256 0.384C673.6 512.768 671.232 526.592 661.76 535.968z"
        p-id="1740"
        fill="currentColor"
      />
    </svg>
  )
}
